<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row type="flex" :gutter="10" style="width: 100vw">
          <el-col :span="3">
            <el-input
              v-model="filterOption.account"
              :placeholder="$t('user.account')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.name"
              :placeholder="$t('user.name')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.mobile"
              :placeholder="$t('user.mobile')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.jobNumber"
              :placeholder="$t('user.jobNumber')"
              size="mini"
            ></el-input>
          </el-col>

          <el-col :span="3">
            <el-select
              filterable
              v-model="filterOption.orgId"
              :placeholder="$t('user.organization')"
              clearable
              size="mini"
            >
              <el-option
              :label="item.companyNameCn"
              :value="'' + item.id"
              v-for="(item, index) in organizationList"
              :key="index"
            >
              <span style="float: left">{{ item.companyNameCn }}</span>
              <span
                style="
                  float: right;
                  color: 'var(--el-text-color-secondary)';
                  font-size: 12px;
                "
                >{{ item.companyCode }}</span
              ></el-option
            >
            </el-select>
          </el-col>
          <el-col :span="4" :offset="5">
            <div style="float: right">
              <el-button type="primary" size="mini" @click="doSearch">{{
                $t("system.search")
              }}</el-button>
              <el-button type="warning" size="mini" @click="doReset">{{
                $t("system.reset")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <el-button size="mini" @click="showCreateDialog">{{
            $t("system.create")
          }}</el-button>
          <el-button size="mini" @click="enableUser">{{
            $t("system.enable")
          }}</el-button>
          <el-button size="mini" @click="disableUser">{{
            $t("system.disable")
          }}</el-button>
        </div>
        <el-table
          :data="tableData"
          ref="accountTable"
          border
          :height="tHeight"
          v-loading="isTableLoading"
          @selection-change="onTableSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column type="selection"  fixed></el-table-column>
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="80"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="account"
            :label="$t('user.account')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="name"
            :label="$t('user.name')"
            align="center"
         
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('user.status')"
            align="center"
            width="100"
            show-overflow-tooltip
          >
            <template #default="scope">
              <span>
                <el-tag v-if="scope.row.locked == 0" type="success">{{
                  $t("system.enable")
                }}</el-tag>
                <el-tag v-else type="danger">{{ $t("system.disable") }}</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="nickname"
            :label="$t('user.nickName')"
            align="center"
          
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="birthday"
            :label="$t('user.birthday')"
            align="center"
       
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('user.sex')"
            align="center"
            width="80"
            show-overflow-tooltip
          >
            <template #default="scope">
              <span>{{
                scope.row.sex === "0" ? $t("user.male") : $t("user.female")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="email"
            :label="$t('user.email')"
            align="center"
            width="250"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="mobile"
            :label="$t('user.mobile')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="tel"
            :label="$t('user.tel')"
            align="center"
           
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="orgId"
            :label="$t('user.organization')"
            align="center"
            width="200"
            show-overflow-tooltip
            ><template #default="scope">
              {{ getOrgName(scope.row.orgId) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="jobNumber"
            :label="$t('user.jobNumber')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="position"
            :label="$t('user.position')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="200"
          >
            <template #default="scope">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                >{{ $t("system.edit") }}</el-button
              >
              <el-button
                type="text"
                style="color: #e6a23c"
                size="mini"
                icon="el-icon-refresh-left"
                @click="resetPassword(scope.row)"
                >{{ $t("user.resetPassword") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.pageNo"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>

    <create-account-dialog
      v-if="isShowCreateDialog"
      :isShowDialog="isShowCreateDialog"
      @onDialogClose="closeCreateDialog"
    />
    <update-account-dialog
      v-if="isShowUpdateDialog"
      :isShowDialog="isShowUpdateDialog"
      :rowInfo="currentRow"
      @onDialogClose="closeUpdateDialog"
    />
  </div>
</template>

<script>
import accountServer from "./source/account.js";
import mixin from "@/utils/mixin.js";
import CreateAccountDialog from "./dialogs/createAccount";
import UpdateAccountDialog from "./dialogs/updateAccount";
export default {
  name: "user",
  components: {
    CreateAccountDialog,
    UpdateAccountDialog,
  },
  mixins: [mixin],
  data() {
    return {
      isTableLoading: false,
      isShowCreateDialog: false,
      isShowUpdateDialog: false,
      filterOption: {
        account: null,
        name: null,
        mobile: null,
        orgId: null,
        jobNumber: null,
        position: null,

        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" }
      // ],

      tableData: [],
      tableSelection: [],
      currentRow: null,
    };
  },
  computed: {
    organizationList() {
      return this.$store.state.constants.saleCompanys;
    },
    tHeight() {
      return this.tableHeight - 135;
    },
  },
  mounted() {},
  methods: {
    getOrgName(orgId) {
      let orgInfo = this.organizationList.find((item) => orgId == "" + item.id);
      return orgInfo ? orgInfo.companyNameCn : "-";
    },
    doSearch() {
      accountServer
        .queryUser({
          ...this.filterOption,
          current: this.filterOption.pageNo,
          size: this.filterOption.pageSize,
        })
        .then((res) => {
          this.tableData = res.data ? res.data.records : [];
          this.filterOption.total = res.data.total;
        });
    },
    doReset() {
      this.filterOption = {
        account: null,
        name: null,
        mobile: null,
        orgId: null,
        jobNumber: null,
        position: null,
        pageNo: 1,
        pageSize: 10,
        total: 0,
      };
    },
    edit(row) {
      this.currentRow = row;
      this.isShowUpdateDialog = true;
    },
    resetPassword(row) {
      this.$confirm(
        this.$t("user.resetPassword") + ` [ ${row.name} ]`,
        this.$t("system.continue") + "?",
        {
          confirmButtonText: this.$t("system.confirm"),
          cancelButtonText: this.$t("system.cancel"),
          type: "warning",
        }
      ).then(() => {
        accountServer.resetPassword({ id: row.id }).then(() => {
          this.$message.success(this.$t("user.resetPasswordSuccess"));
          this.doSearch();
        });
      });
    },
    enableUser() {
      if (this.tableSelection.length == 0) {
        this.$message.error(this.$t("system.emptySelection"));
        return;
      }
      let ids = [];
      this.tableSelection.forEach((item) => {
        ids.push(item.id);
      });
      accountServer.enableUser({ ids: ids }).then(() => {
        this.$message.success(this.$t("user.enableSuccess"));
        this.doSearch();
      });
    },
    disableUser() {
      if (this.tableSelection.length == 0) {
        this.$message.error(this.$t("system.emptySelection"));
        return;
      }
      let ids = [];
      this.tableSelection.forEach((item) => {
        ids.push(item.id);
      });
      accountServer.disableUser({ ids: ids }).then(() => {
        this.$message.success(this.$t("user.disableSuccess"));
        this.doSearch();
      });
    },

    onTableSelectionChange(val) {
      this.tableSelection = val;
    },
    onTableSizeChange(size) {
      this.filterOption = { ...this.filterOption, pageSize: size, pageNo: 1 };
      this.doSearch();
    },
    onTablePageChange(page) {
      this.filterOption = { ...this.filterOption, pageNo: page };
      this.doSearch();
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    closeUpdateDialog() {
      this.isShowUpdateDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
