<template>
  <el-dialog
    :title="$t('user.createAccount')"
    v-model="isShow"
    width="700px"
    :before-close="handleClose"
  >
    <el-form
      ref="userForm"
      :inline="true"
      :model="accountInfo"
      size="mini"
      :rules="accountRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('user.account')"
          prop="account"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.account"
            :placeholder="$t('system.pleaseInput') + $t('user.account')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.name')" prop="name" label-width="120px">
          <el-input
            v-model.trim="accountInfo.name"
            :placeholder="$t('system.pleaseInput') + $t('user.name')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('user.password')"
          prop="password"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.password"
            :placeholder="$t('system.pleaseInput') + $t('user.password')"
            type="password"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('user.password_confirm')"
          prop="passwordConfirm"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.passwordConfirm"
            type="password"
            :placeholder="
              $t('system.pleaseInput') + $t('user.password_confirm')
            "
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('user.nickName')"
          prop="nickName"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.nickname"
            :placeholder="$t('system.pleaseInput') + $t('user.nickName')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('user.birthday')"
          prop="birthday"
          label-width="120px"
        >
          <el-date-picker
            v-model="accountInfo.birthday"
            type="date"
            :placeholder="$t('system.pleaseSelect') + $t('user.birthday')"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('user.mobile')"
          prop="mobile"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.mobile"
            :placeholder="$t('system.pleaseInput') + $t('user.mobile')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.tel')" prop="tel" label-width="120px">
          <el-input
            v-model.trim="accountInfo.tel"
            :placeholder="$t('system.pleaseInput') + $t('user.tel')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('user.organization')"
          prop="orgId"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="accountInfo.orgId"
            :placeholder="$t('system.pleaseSelect') + $t('user.organization')"
            clearable
            @change="changeOrganization"
          >
            <el-option
              :label="item.companyNameCn"
              :value="'' + item.id"
              v-for="(item, index) in organizationList"
              :key="index"
            >
              <span style="float: left">{{ item.companyNameCn }}</span>
              <span
                style="
                  float: right;
                  color: 'var(--el-text-color-secondary)';
                  font-size: 12px;
                "
                >{{ item.companyCode }}</span
              ></el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('user.jobNumber')"
          prop="jobNumber"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.jobNumber"
            :placeholder="$t('system.pleaseInput') + $t('user.jobNumber')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('user.position')"
          prop="position"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.position"
            :placeholder="$t('system.pleaseInput') + $t('user.position')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('user.email')"
          prop="email"
          label-width="120px"
        >
          <el-input
            v-model.trim="accountInfo.email"
            :placeholder="$t('system.pleaseInput') + $t('user.email')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doCreateAccount" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import accountServer from "../source/account";
import { validatePassword } from "@/utils/validator.js";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    const validatePasswordConfirm = (rule, value, callback) => {
      value = value && value.trim();
      if (value != this.accountInfo.password) {
        callback(new Error(this.$t("validate.pwdNotSame")));
      } else {
        callback();
      }
    };

    return {
      accountInfo: {
        account: null,
        name: null,
        password: null,
        passwordConfirm: null,
        nickname: null,
        mobile: null,
        tel: null,
        position: null,
        birthday: null,
        orgId: null,
        orgName: null,
        email: null,
        jobNumber: null,
      },
      accountRules: {
        account: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.account"),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.name"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.password"),
            trigger: "blur",
          },
          {
            validator: validatePassword,
            trigger: "blur",
            msg: {
              pwdLength: this.$t("validate.pwdLength"),
              pwdReg: this.$t("validate.pwdReg"),
            },
          },
        ],
        passwordConfirm: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("user.password_confirm"),
            trigger: "blur",
          },
          { validator: validatePasswordConfirm, trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.mobile"),
            trigger: "blur",
          },
        ],
        // position: [
        //   {
        //     required: true,
        //     message: this.$t("system.pleaseInput") + this.$t("user.position"),
        //     trigger: "blur",
        //   },
        // ],
      },
      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" },
      // ],
    };
  },
  computed: {
    organizationList() {
      return this.$store.state.constants.saleCompanys;
    },
    isShow() {
      return this.isShowDialog;
    },
  },
  methods: {
    changeOrganization(val) {
      if (val) {
        this.accountInfo.orgName = this.organizationList.find(
          (item) => item.id == val
        ).companyNameCn;
      } else {
        this.accountInfo.orgName = null;
      }
    },
    doCreateAccount() {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          accountServer.addUser(this.accountInfo).then(() => {
            this.$confirm(
              this.$t("user.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success",
              }
            )
              .then(() => {
                this.$refs["userForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    },
    handleClose() {
      this.$emit("onDialogClose");
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
