const mixin = {
    data() {
        return {
            tableHeight: 0,
            heightDelta: 230
        }
    },
    created() {},
    mounted() {
        let that = this;
        that.$nextTick(() => {
            that.setHeight();
            window.onresize = () => {
                that.setHeight();
            };
            that.doSearch&& that.doSearch();
        });
    },
    methods: {
        setHeight() {
            let all = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            this.tableHeight = all - this.heightDelta;
        },

        formatNullToValue(orgVal, defaultVal) {
            return orgVal == null ? defaultVal : orgVal;
        }
    }
}

export default mixin;