import request from "@/api/request";

const accountServer = {

    /** 用户管理 */

    queryUser: params =>
        request({
            url: "/baseUser/queryUser",
            method: "get",
            params: params,
        }),
    addUser: params =>
        request.post('/baseUser/addUser', params),
    updateUser: params =>
        request.post('/baseUser/updateUser', params),
    resetPassword: ({
        id
    }) =>
        request.post('/baseUser/resetPwd?userId=' + id, {}),
    enableUser: params =>
        request.post('/baseUser/unlockUsers', params),
    disableUser: params =>
        request.post('/baseUser/lockUsers', params),


    /** 角色管理 */
    queryRole: params =>
        request({
            url: "/role/queryRole/" + params.tenant,
            method: "get",
            params: params,
        }),
    queryMenuTree: params =>
        request({
            url: "/menu/tree/" + params.tenantType,
            method: "get",
            params: params,
        }),
    queryResources: params =>
        request({
            url: "/resources/" + params.tenantType,
            method: "get",
            params: params,
        }),

    addRole: params =>
        request.post('/role/addRole', params),
    roleDetail: params =>
        request({
            url: "/role/roleDetail/" + params.roleId,
            method: "get",
            params: params,
        }),
    updateRole: params =>
        request.post('/role/updateRole', params),
    queryBindedUser: params =>
        request({
            url: "/role/queryRoleBindedUser/" + params.roleId,
            method: "get",
            params: params,
        }),
    distributeRole: params =>
        request.post('/role/' + params.roleId + '/distributeRole', params),

    queryUserDateAuth: params =>
        request({
            url: "/baseUser/queryUserDateAuth",
            method: "get",
            params: params,
        }),
    queryDepartment: params =>
        request({
            url: "/customer/queryDepartment",
            method: "get",
            params: params,
        }),
    bindUserDateAuth: params =>
        request.post('/baseUser/bindUserDateAuth', params),

}




export default accountServer